<template>
  <div class="home">
    <!-- <div class="container-to-color">
        <div class="container-mod">
          
        </div> 
      </div>-->
    <home-header v-if="isSwiperMode && $route.path === '/'" />
    <recycle-games v-if="isSwiperMode" />
    <!--  <swiper :options="swiperOption"
      v-if="isSwiperMode"
      class="swiper"
      ref="swiper">
      <swiper-slide data-hash="jackpots" class="home__section home__section--jackpots">
        <home-jackpots />
      </swiper-slide> 
      <swiper-slide data-hash="games" class="home__section home__section--games">
        <recycle-games />
      </swiper-slide>
        <swiper-slide data-hash="providers" class="home__section home__section--providers">
           <home-jackpots /> 
          <providers-slider/>
        </swiper-slide>
     <div class="swiper-pagination" slot="pagination"></div>
      </swiper>  -->
    <template v-else>
      <home-jackpots />
      <recycle-games />
      <desktop-menu />
      <providers-slider />
      <desktop-footer></desktop-footer>
    </template>

    <!-- <section class="container-to-color game-blocks" 
      :class="{ '_first' : index === 0, '_no-games' : block.games.length === 0}"
      v-for="(block, index) in sortedGameBlocks"
      :key="'game_' + index">
        <div class="container-mod" v-if="block.games.length > 0">
          <component :is="'GamesSection'" :block_id="block.block_id" :games="block.games.slice(0, 20)" :block_name="block.block_name">

          </component>
        </div>
        
      </section>
        <section class="container-to-color game-blocks" 
        :class="{ '_provider' : block.component === 'ProviderSection', '_first' : index === 0 }"
        v-for="(block, index) in blocks" :key="index">
          <div class="container-mod">
            <component :is="block.component" :category="block.category" :isType="block.isType">
  
            </component>
          </div>
          
        </section> -->
  </div>
  <!-- <swiper class="swiper" :modules="modules" direction="vertical" :pagination="{ clickable: true }">
      <swiper-slide class="slide">Slide 1</swiper-slide>
      <swiper-slide class="slide">Slide 2</swiper-slide>
      <swiper-slide class="slide">Slide 3</swiper-slide>
      <swiper-slide class="slide">Slide 4</swiper-slide>
      <swiper-slide class="slide">Slide 5</swiper-slide>
      <swiper-slide class="slide">Slide 6</swiper-slide>
      <swiper-slide class="slide">Slide 7</swiper-slide>
      <swiper-slide class="slide">Slide 8</swiper-slide>
    </swiper> -->
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
import { mapGetters, mapActions } from "vuex";
import DesktopFooter from "@/components/DesktopFooter.vue";
import HomeHeader from "@/components/HomeHeader.vue";
import DesktopMenu from "@/components/DesktopMenu/DesktopMenu.vue";
import ProvidersSlider from "@/components/ProvidersSlider";
import HomeJackpots from "@/components/Home/HomeJackpots";
import RecycleGames from "@/components/Games/RecycleGames";
//========================================================================================================================================================
//========================================================================================================================================================
export default {
  name: "Home",
  components: {
    // Swiper,
    // SwiperSlide,
    DesktopMenu,
    ProvidersSlider,
    HomeJackpots,
    DesktopFooter,
    HomeHeader,
    RecycleGames,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      filters: {
        size: 100,
        page: 1,
        provider: null,
      },
      swiperOption: {
        spaceBetween: 30,
        hashNavigation: {
          watchState: true,
        },
        direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      providers: "gameModule/getProviders",
      user: "getUserInfo",
    }),
    isSwiperMode() {
      return this.windowWidth <= 1024;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
    if (!this.providers.items.length) {
      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: true,
      });
      await this.$store.dispatch("gameModule/awaitGetProviders");
      this.filters.provider = this.providers.items[0].string_id;
      await this.$store.dispatch("gameModule/awaitGetGames", this.filters);
      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: false,
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  watch: {},
  created() {},
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style lang="scss">
.swiper-container {
  margin-left: unset;
  margin-right: unset;
}
.home {
  position: relative;
  flex: 1 1 100%;
  &__section {
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
.game-blocks {
  padding-top: 1px !important;
  &:not(:last-child) {
    padding-bottom: 15px !important;
  }
  &._provider {
    background-color: var(--light-flamingo);
  }
  &._first {
    padding-top: 15px !important;
  }
  &._no-games {
    padding: 0px !important;
  }
}

.v-menu__content::-webkit-scrollbar {
  width: 7px;
  background-color: var(--white);
}

.v-menu__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray);
}

#providers_select_icon {
  transition: transform 0.3s;
}

.v-select--is-menu-active {
  #providers_select_icon {
    transform: rotate(-180deg);
  }
}

.svg-inline--fa {
  vertical-align: 0;
  margin: 0 5px;
}

.container-to-color {
  background-color: var(--main-flamingo);
}

.container-mod {
  max-width: 1330px !important;
  margin: 0 auto;
  padding: 0 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--third-flamingo);
  -webkit-box-shadow: 0 0 0px black inset;
}

.v-overlay__scrim {
  background-color: var(--main-flamingo) !important;
}

.v-select:not(.v-autocomplete) .v-select__slot input {
  visibility: hidden;
}

.theme--light.v-list {
  text-align: left;
  background-color: var(--main-flamingo) !important;
  color: var(--white);

  :not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--white);
  }
}

.selectMenuClass {
  border: solid 1px var(--white);
}
</style>
