// gameModule.js
import axios from "axios";
const state = {
  loadingProviders: false,
  providersSearchTrigger: false,
  currentTab: "all",
  games: {
    items: [],
    page: 1,
    pages: 1,
    size: 1,
    total: 1,
  },
  currentProvider: null,
  providers: {
    items: [],
    page: 1,
    pages: 1,
    size: 1,
    total: 1,
  },
  currentGame: null,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  APPEND_CURRENT_GAMES: (state, games) => {
    state.games.items = state.games.items.concat(games.items);

    state.games.page = games.page;
    state.games.pages = games.pages;
    state.games.size = games.size;
    state.games.total = games.total;
  },
};

const getters = {
  getCurrentTab: (state) => state.currentTab,
  getGames: (state) => state.games,
  getProviders: (state) => state.providers,
  getCurrentProvider: (state) => state.currentProvider,
};

const actions = {
  async awaitGetProviders({ commit }) {
    await axios
      .get(`/api/games/my/providers?size=100`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // var providers = [];
          const providers = {
            items: response.data.items
              .filter((prov) => prov.name !== "All Games")
              .map((providerData) => ({
                id: providerData.id,
                value: providerData.name,
                text: providerData.name,
                logo: providerData.logo,
                enabled_games_count: providerData.enabled_games_count,
                string_id: providerData.string_id,
              })),
            page: response.data.page,
            pages: response.data.pages,
            size: response.data.size,
            total: response.data.total,
          };
          commit("set", {
            type: "providers",
            items: providers,
          });
        }
      })
      .catch((error) => {
        console.log("error = ", error);
        throw error;
      });
  },
  async awaitGetGames({ commit, state }, params) {
    // let params = filters;
    // if (!filters.provider) {
    //   params = {
    //     ...filters,
    //     provider: state.currentProvider,
    //   };
    // }
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    commit("set", { type: "currentProvider", items: params.provider });
    try {
      const response = await axios.get(`/api/games/my/filter?${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items;
        const result = {
          items: games,
          page: paggination.page,
          pages: paggination.pages,
          size: paggination.size,
          total: paggination.total,
        };
        if (params.page >= 2) {
          commit("APPEND_CURRENT_GAMES", result);
        } else {
          commit("set", {
            type: "games",
            items: result,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  },
  async awaitGetGame({ commit }, id) {
    try {
      const response = await axios.get(`/api/games/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (response.status === 200) {
        commit("set", {
          type: "currentGame",
          items: response.data,
        });
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  },
  async getMoreGames({ commit }, params) {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    try {
      // my/

      const response = await axios.get(`/api/games/my/filter?${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items;
        commit("HOLD_CURRENT_PAGGINATION", paggination);
        return games;
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
