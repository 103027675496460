<template>
  <div class="providers-list">
    <div ref="swiper" class="providers__items" v-if="!loadingFlag">
      <!-- Additional required wrapper -->
      <carousel-3d
        class="carousel-menu"
        ref="providersSlider"
        v-if="providers.items.length > 0 && showCarousel"
        :display="3"
        :disable3d="true"
        :space="135"
        :key="$route.fullPath"
        :controls-visible="false"
        @before-slide-change="onBeforeSlideChange"
        :count="providers.size"
        :border="0"
        :height="40"
        :width="120"
      >
        <slide
          v-for="(provider, i) in providers.items"
          @click="selectProvider(provider)"
          :key="provider.id"
          :index="i"
        >
          <div class="provider__content">
            <span class="provider__name"> {{ provider.text }} </span>
            <span class="provider__count"
              >{{
                provider.enabled_games_count ? provider.enabled_games_count : 0
              }}
              {{ $t("games") }}
            </span>
          </div>
        </slide>
      </carousel-3d>
    </div>
    <div class="providers__items" v-else>
      <v-skeleton-loader
        width="100%"
        height="100%"
        type="image"
        class="skeleton-full"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  name: "BottomProvidersList",
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      loadingFlag: false,
      showCarousel: true,
      isActive: false,
      filters: {
        size: 100,
        page: 1,
        provider: null,
      },
      windowWidth: 0,
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      searchedProvider: null,
      handleGoTo: false,
    };
  },
  watch: {
    carouselHeight(newHeight) {
      this.forceCarouselRerender();
    },
    goToTrigger: {
      handler(newV) {
        if (newV) {
          const prov = this.searchedProvider;
          const index = this.providers.items.findIndex(
            (item) => item.id === prov
          );
          setTimeout(() => {
            this.goToSlide(index);
          }, 200);
          this.$store.commit("gameModule/set", {
            type: "providersSearchTrigger",
            items: false,
          });
        }
      },
    },
    "games.items": {
      handler(newV, oldV) {
        if (newV && newV.length > 0) {
          const prov = newV[0].provider_id;
          this.searchedProvider = prov;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      providers: "gameModule/getProviders",
      games: "gameModule/getGames",
    }),
    goToTrigger() {
      return this.$store.state.gameModule.providersSearchTrigger;
    },
    carouselHeight() {
      return this.isSwiperMode ? 200 : 100;
    },
  },
  methods: {
    forceCarouselRerender() {
      this.showCarousel = false;
      this.$nextTick(() => {
        this.showCarousel = true;
      });
      const prov = this.searchedProvider;
      const index = this.providers.items.findIndex((item) => item.id === prov);

      setTimeout(() => {
        this.goToSlide(index);
      }, 200);
    },
    goToSlide(index) {
      if (this.$refs.providersSlider) {
        this.handleGoTo = true;
        this.$refs.providersSlider.goSlide(index);
      }
    },
    async onBeforeSlideChange(index) {
      if (this.handleGoTo) {
        this.handleGoTo = false;
        return;
      }
      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: true,
      });
      const provider = this.providers.items[index];
      this.$store.commit("gameModule/set", {
        type: "currentTab",
        items: "all",
      });
      this.filters.provider = provider.string_id;
      await this.$store.dispatch("gameModule/awaitGetGames", this.filters);
      this.$store.commit("gameModule/set", {
        type: "loadingProviders",
        items: false,
      });
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    if (this.games.items && this.games.items.length > 0) {
      const prov = this.games.items[0].provider_id;
      this.searchedProvider = prov;
    }
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
.providers-list {
  backdrop-filter: blur(52px);
  opacity: 1;
  bottom: 0px;
  transition-delay: 0.3s;
  //min-height: 100px;
  padding: 0px;
  position: fixed;
  width: 100%;
  z-index: 0;
  @media (min-width: 1024.98px) {
    .is-desktop & {
      display: none;
    }
  }
  background: linear-gradient(
    to bottom right,
    rgba(2, 99, 225, 1),
    rgba(235, 24, 54, 1)
  );
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
.providers {
  padding: 0;

  @media (min-width: 1025px) {
    display: flex;
    flex-direction: column;

    left: 50%;
    bottom: 36px;
    width: 615px;
    max-width: 50%;
    height: 93px;
    position: absolute;
    z-index: 100;
    background-image: radial-gradient(
      ellipse at top,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.5) 100%
    );
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  @media (max-width: 1024px) {
    height: 100%;
    min-width: 100%;
    padding: 0px;
  }
  &__arrow {
  }

  &__arrow--prev {
  }

  &__items {
    background-color: var(--dark-flamingo);
    margin: 2px;
    height: 100%;
    position: relative;
    //display: flex;
    //flex-direction: column;
    //justify-content:center;
    //align-items: center;
    //flex: 1 1 100%;
    //mask-image: linear-gradient(90deg, transparent 0, #000 40%, #000 60%, transparent);
    //-webkit-mask-image: linear-gradient(90deg, transparent 0, #000 40%, #000 60%, transparent);
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .carousel-3d-container {
      margin: 0;
    }
    .carousel-3d-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper {
      min-width: 0;
      max-width: 100%;
      min-height: 0%;
      max-height: 100%;
      //  .swiper-wrapper{
      //  flex: 0 0 100%;
      //  min-width: 0;
      //  max-width: 100%;
      //  min-height: 0%;
      //  max-height: 100%;
      //}
      //.swiper-slide{
      //  flex: 0 0 100%;
      //  min-width: 0;
      //  max-width: 100%;
      //  min-height: 0%;
      //  max-height: 100%;
      //
      //}
      //min-width: 0;
    }
  }

  &__arrow--next {
  }
}

.provider {
  position: relative;

  &__item {
    width: 170px;
    display: flex;
    flex: 0 0 170px;
    flex-direction: column;
    padding: 10px 0;
    min-height: 100px;
    height: auto;
    @media (any-hover: hover) {
      &:hover {
        .provider__image {
          transform: scale(1.08);
        }
      }
    }
  }

  &__body {
    height: 100%;
    //border: 1px solid var(--white);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3) !important;
    border-radius: 16px !important;
    background-color: var(--dark-d-flamingo);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all 0.3s ease-out;
    overflow: hidden;
    color: var(--white);
    &::before {
      content: "";
      background-color: rgba(white, 0.4);
      width: 60%;
      height: 100%;
      top: 0px;
      left: -125%;
      transform: skew(45deg);
      z-index: 0;
      position: absolute;
      opacity: 0;
      transition: left 0.5s ease-out, opacity 0.1s;
    }
    &:hover {
      cursor: pointer;
      &::before {
        left: 150%;
        opacity: 1;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    color: var(--white);
    text-align: center;
    //background-color: var(--dark-flamingo);
  }
  &__name {
    text-transform: capitalize;
    font-size: 12px;
    transition: all 0.3s;
    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: 20px;
      max-width: 200px;
    }
  }
  &__count {
    font-size: 12px;
  }
  &__image {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    transition: transform 0.3s;
    img {
      vertical-align: middle;
      max-height: 50px;
      max-width: 140px;
      object-fit: cover;
      -webkit-transform: translateZ(0);
      object-position: center center;
    }
  }
}
</style>
